<template>
    <div>
        <div class="divider" style="margin-top: 0;margin-bottom: 5px;">
            <span style="color:#555;"><v-icon style="color:#555!important;">child_care</v-icon>My Children</span>
        </div>

        <div class="member-stat-bar children-box">
            <ul v-if="children">
                <li v-for="(child ,key) in children" :key="key">
                    <!--{{child}} check..-->
                    <div class="representation">
                        <img :src="child.profile_image" alt="">
                        <!--<img src="https://image.flaticon.com/icons/svg/201/201608.svg" alt="">-->
                    </div>
                    <div class="member-label">
                        <div style="display: flex;justify-content: space-between">
                            <div>
                                <h3>{{child.full_name}}</h3>
                                <p v-if="child.academic">{{child.academic.grade}} ({{child.academic.section}}), Roll
                                    {{child.academic.roll_no}}, <strong style="color:#888;">{{child.academic.enroll_code}}</strong>
                                </p>
                            </div>
                            <div>
                                <a href="">Dashboard</a>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        props: {
            children: {
                required: true,
                type: Array,
            }
        },
        data() {
            return {
                memberCount: {
                    teacher: {
                        total: '',
                        male: '',
                        female: ''
                    },
                    student: {
                        total: '',
                        male: '',
                        female: ''
                    },
                    guardian: 0,
                    member: 0
                }
            }
        },
        computed: {
            ...mapState(['members']),
            allMemberCount() {
                return this.memberCount.student.total + this.memberCount.guardian + this.memberCount.member;
            },
            otherMembers() {
                return this.memberCount.member - this.memberCount.teacher.total;
            }
        },
        mounted() {
            this.memberCountCall();
        },
        methods: {
            memberPercentageEvaluation(count) {
                if (!count)
                    return 0;
                return (count * 100) / this.allMemberCount;
            },

            memberCountCall() {
                this.$rest.get('api/dashboard/gender-count').then(data => {
                    let studentTotal = 0;
                    let $this = this;
                    this.memberCount.member = data.data.member;
                    this.memberCount.guardian = data.data.guardian.guardian;
                    data.data.student.map(function (item) {
                        studentTotal += item.cnt;
                        if (item.gender === 'FEMALE')
                            $this.memberCount.student.female = item.cnt;

                        if (item.gender === 'MALE')
                            $this.memberCount.student.male = item.cnt;
                    });
                    this.memberCount.student.total = studentTotal;

                    let teacherTotal = 0;

                    data.data.teacher.map(function (item) {
                        teacherTotal += item.cnt;
                        if (item.gender === 'FEMALE')
                            $this.memberCount.teacher.female = item.cnt;
                        if (item.gender === 'MALE')
                            $this.memberCount.teacher.male = item.cnt;
                    });
                    this.memberCount.teacher.total = teacherTotal;
                });
            },
        }
    }
</script>